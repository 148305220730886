import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { AdminComponent } from './admin.component';
import { ConfigComponent } from './config/config.component';
import { IssuesComponent } from './issues/issues.component';
import { Roles } from './roles';
import { RolesComponent } from './roles/roles.component';
import { TrainingPlanComponent } from '../training/training-plan/training-plan.component';

function checkUserRole(role: Roles): boolean {
  const userService = inject(UserService);
  return (
    userService.snapshotUser != undefined &&
    userService.snapshotUser.roles != undefined &&
    userService.snapshotUser.roles.indexOf(role.toString()) >= 0
  );
}

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    title: 'Admin',
    canActivate: [() => checkUserRole(Roles.Internal)],
    children: [
      {
        path: 'roles',
        component: RolesComponent,
        title: 'Admin - Roles',
        canActivate: [() => checkUserRole(Roles.Administrator)],
      },
      {
        path: 'issues/:id',
        component: IssuesComponent,
        title: 'Admin - Issues',
        canActivate: [() => checkUserRole(Roles.IssueReviewer)],
      },
      {
        path: 'issues',
        component: IssuesComponent,
        title: 'Admin - Issues',
        canActivate: [() => checkUserRole(Roles.IssueReviewer)],
      },
      {
        path: 'config',
        component: ConfigComponent,
        title: 'Admin - Config',
        canActivate: [() => checkUserRole(Roles.Administrator)],
      },
      {
        path: 'training/plan',
        component: TrainingPlanComponent,
        title: 'Admin - Training Plan',
        canActivate: [() => checkUserRole(Roles.Administrator)],
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
