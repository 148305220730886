import { Injectable } from '@angular/core';
import {
  BuildTrainingPlanRequest,
  BuildTrainingPlanResponse,
} from 'generated/src/main/proto/api/chat-service.pb';
import { ChatServiceClient } from 'generated/src/main/proto/api/chat-service.pbsc';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { BannerMessage, BannerService } from '../banner/banner.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(
    private chatServiceClient: ChatServiceClient,
    private userService: UserService,
    private bannerService: BannerService,
  ) {}

  async buildTrainingPlan(prompt: string): Promise<BuildTrainingPlanResponse> {
    const request = new BuildTrainingPlanRequest({ prompt: prompt });
    return lastValueFrom(
      this.chatServiceClient
        .buildTrainingPlan(request, this.userService.userTokenMetadata)
        .pipe(
          catchError((e) => {
            this.bannerService.add(new BannerMessage(e.statusMessage));
            return throwError(() => e);
          }),
        ),
    );
  }
}
