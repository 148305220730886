import { Component } from '@angular/core';
import {
  BannerMessage,
  BannerService,
} from 'src/app/services/banner/banner.service';
import { ChatService } from 'src/app/services/chat/chat.service';

@Component({
  selector: 'app-training-plan',
  templateUrl: './training-plan.component.html',
  styleUrl: './training-plan.component.scss',
})
export class TrainingPlanComponent {
  prompt = '';
  markdownResponse = '';

  generatingResponse = false;

  constructor(
    private chatService: ChatService,
    private bannerService: BannerService,
  ) {}

  onGenerate(): void {
    this.markdownResponse = '';
    this.generatingResponse = true;
    this.chatService
      .buildTrainingPlan(this.prompt)
      .then((r) => {
        // Replace markdown section for the actual plan with two separators.
        this.markdownResponse = r.markdownResponse.replace(
          /```markdown([\s\S]*?)```/,
          '---\n$1---',
        );
        this.generatingResponse = false;
      })
      .catch((reason) => {
        this.generatingResponse = false;
        this.bannerService.add(new BannerMessage(reason));
      });
  }

  isGenerateDisabled(): boolean {
    return !this.prompt || this.generatingResponse;
  }
}
