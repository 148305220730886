import { Component } from '@angular/core';
import { FormatService } from '../services/format.service';
import { LoggedInUser, UserService } from '../services/user/user.service';
import { Roles } from './roles';

type NavigationLink = {
  label: string;
  route: string;
  role: Roles;
};

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  readonly width: number;
  readonly margin: number;
  readonly innerWidth: number;

  private static readonly NAV_LINKS: NavigationLink[] = [
    {
      label: 'Roles',
      route: '/admin/roles',
      role: Roles.Administrator,
    },
    {
      label: 'Issues',
      route: '/admin/issues',
      role: Roles.IssueReviewer,
    },
    {
      label: 'Config',
      route: '/admin/config',
      role: Roles.Administrator,
    },
    {
      label: 'Plan',
      route: '/admin/training/plan',
      role: Roles.Administrator,
    },
  ];

  navLinks?: NavigationLink[];

  constructor(userService: UserService, formatService: FormatService) {
    this.width = formatService.viewWidth;
    this.margin = 7;
    this.innerWidth = this.width - 2 * this.margin;

    userService.user.subscribe((user) => {
      this.navLinks = this.filterNavigationLinks(user);
    });
  }

  private filterNavigationLinks(user?: LoggedInUser): NavigationLink[] {
    return AdminComponent.NAV_LINKS.filter(
      (link) =>
        user != undefined &&
        user.roles != undefined &&
        user.roles.indexOf(link.role) >= 0,
    );
  }
}
