<div class="contents">
  <div class="prompt-row">
    <!-- User prompt -->
    <mat-form-field class="prompt" [floatLabel]="'always'">
      <mat-label>Model Prompt</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
        [(ngModel)]="prompt"
        placeholder="...prompt for the model"
        maxlength="2000"
      ></textarea>
    </mat-form-field>

    <!-- Generate button -->
    <button
      mat-icon-button
      class="generate-button"
      aria-label="Generate plan button"
      matTooltip="Generate Plan"
      [disabled]="isGenerateDisabled()"
      (click)="onGenerate()"
    >
      <mat-icon>cached</mat-icon>
    </button>
  </div>

  <!-- Model response -->
  <markdown
    *ngIf="markdownResponse"
    mermaid
    [data]="markdownResponse"
  ></markdown>
</div>
