/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for api.chat.BuildTrainingPlanRequest
 */
export class BuildTrainingPlanRequest implements GrpcMessage {
  static id = 'api.chat.BuildTrainingPlanRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BuildTrainingPlanRequest();
    BuildTrainingPlanRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BuildTrainingPlanRequest) {
    _instance.prompt = _instance.prompt || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BuildTrainingPlanRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.prompt = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BuildTrainingPlanRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BuildTrainingPlanRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.prompt) {
      _writer.writeString(1, _instance.prompt);
    }
  }

  private _prompt: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BuildTrainingPlanRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<BuildTrainingPlanRequest.AsObject>) {
    _value = _value || {};
    this.prompt = _value.prompt;
    BuildTrainingPlanRequest.refineValues(this);
  }
  get prompt(): string {
    return this._prompt;
  }
  set prompt(value: string) {
    this._prompt = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BuildTrainingPlanRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BuildTrainingPlanRequest.AsObject {
    return {
      prompt: this.prompt
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BuildTrainingPlanRequest.AsProtobufJSON {
    return {
      prompt: this.prompt
    };
  }
}
export module BuildTrainingPlanRequest {
  /**
   * Standard JavaScript object representation for BuildTrainingPlanRequest
   */
  export interface AsObject {
    prompt: string;
  }

  /**
   * Protobuf JSON representation for BuildTrainingPlanRequest
   */
  export interface AsProtobufJSON {
    prompt: string;
  }
}

/**
 * Message implementation for api.chat.BuildTrainingPlanResponse
 */
export class BuildTrainingPlanResponse implements GrpcMessage {
  static id = 'api.chat.BuildTrainingPlanResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BuildTrainingPlanResponse();
    BuildTrainingPlanResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BuildTrainingPlanResponse) {
    _instance.markdownResponse = _instance.markdownResponse || '';
    _instance.prompt = _instance.prompt || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BuildTrainingPlanResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.markdownResponse = _reader.readString();
          break;
        case 2:
          _instance.prompt = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BuildTrainingPlanResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BuildTrainingPlanResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.markdownResponse) {
      _writer.writeString(1, _instance.markdownResponse);
    }
    if (_instance.prompt) {
      _writer.writeString(2, _instance.prompt);
    }
  }

  private _markdownResponse: string;
  private _prompt: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BuildTrainingPlanResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<BuildTrainingPlanResponse.AsObject>) {
    _value = _value || {};
    this.markdownResponse = _value.markdownResponse;
    this.prompt = _value.prompt;
    BuildTrainingPlanResponse.refineValues(this);
  }
  get markdownResponse(): string {
    return this._markdownResponse;
  }
  set markdownResponse(value: string) {
    this._markdownResponse = value;
  }
  get prompt(): string {
    return this._prompt;
  }
  set prompt(value: string) {
    this._prompt = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BuildTrainingPlanResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BuildTrainingPlanResponse.AsObject {
    return {
      markdownResponse: this.markdownResponse,
      prompt: this.prompt
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BuildTrainingPlanResponse.AsProtobufJSON {
    return {
      markdownResponse: this.markdownResponse,
      prompt: this.prompt
    };
  }
}
export module BuildTrainingPlanResponse {
  /**
   * Standard JavaScript object representation for BuildTrainingPlanResponse
   */
  export interface AsObject {
    markdownResponse: string;
    prompt: string;
  }

  /**
   * Protobuf JSON representation for BuildTrainingPlanResponse
   */
  export interface AsProtobufJSON {
    markdownResponse: string;
    prompt: string;
  }
}
