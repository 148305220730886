import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TrainingPlanModule } from '../training/training-plan/training-plan.module';
import { AdminComponent } from './admin.component';
import { ConfigModule } from './config/config.module';
import { IssuesModule } from './issues/issues.module';

@NgModule({
  declarations: [AdminComponent],
  exports: [AdminComponent],
  imports: [
    CommonModule,
    ConfigModule,
    IssuesModule,
    MatTabsModule,
    RouterModule,
    TrainingPlanModule,
  ],
})
export class AdminModule {}
